<template>
  <div class="particles">
    <div class="particle">
      <div class="inner"></div>
    </div>
    <div class="particle">
      <div class="inner"></div>
    </div>
    <div class="particle">
      <div class="inner"></div>
    </div>
    <div class="particle">
      <div class="inner"></div>
    </div>
    <div class="particle">
      <div class="inner"></div>
    </div>
    <div class="particle">
      <div class="inner"></div>
    </div>
    <div class="particle">
      <div class="inner"></div>
    </div>
    <div class="particle">
      <div class="inner"></div>
    </div>
    <div class="particle">
      <div class="inner"></div>
    </div>
    <div class="particle">
      <div class="inner"></div>
    </div>
    <div class="particle">
      <div class="inner"></div>
    </div>
    <div class="particle">
      <div class="inner"></div>
    </div>
    <div class="particle">
      <div class="inner"></div>
    </div>
    <div class="particle">
      <div class="inner"></div>
    </div>
    <div class="particle">
      <div class="inner"></div>
    </div>
    <div class="particle">
      <div class="inner"></div>
    </div>
    <div class="particle">
      <div class="inner"></div>
    </div>
    <div class="particle">
      <div class="inner"></div>
    </div>
    <div class="particle">
      <div class="inner"></div>
    </div>
    <div class="particle">
      <div class="inner"></div>
    </div>
    <div class="particle">
      <div class="inner"></div>
    </div>
    <div class="particle">
      <div class="inner"></div>
    </div>
    <div class="particle">
      <div class="inner"></div>
    </div>
    <div class="particle">
      <div class="inner"></div>
    </div>

    <div class="particle">
      <div class="inner"></div>
    </div>
    <div class="particle">
      <div class="inner"></div>
    </div>
    <div class="particle">
      <div class="inner"></div>
    </div>
    <div class="particle">
      <div class="inner"></div>
    </div>
    <div class="particle">
      <div class="inner"></div>
    </div>
    <div class="particle">
      <div class="inner"></div>
    </div>
    <div class="particle">
      <div class="inner"></div>
    </div>
    <div class="particle">
      <div class="inner"></div>
    </div>
    <div class="particle">
      <div class="inner"></div>
    </div>
    <div class="particle">
      <div class="inner"></div>
    </div>
    <div class="particle">
      <div class="inner"></div>
    </div>
    <div class="particle">
      <div class="inner"></div>
    </div>
    <div class="particle">
      <div class="inner"></div>
    </div>
    <div class="particle">
      <div class="inner"></div>
    </div>
    <div class="particle">
      <div class="inner"></div>
    </div>
    <div class="particle">
      <div class="inner"></div>
    </div>
    <div class="particle">
      <div class="inner"></div>
    </div>
    <div class="particle">
      <div class="inner"></div>
    </div>
    <div class="particle">
      <div class="inner"></div>
    </div>
    <div class="particle">
      <div class="inner"></div>
    </div>
    <div class="particle">
      <div class="inner"></div>
    </div>
    <div class="particle">
      <div class="inner"></div>
    </div>
    <div class="particle">
      <div class="inner"></div>
    </div>
    <div class="particle">
      <div class="inner"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'particles',
};
</script>
